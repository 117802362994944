import {getParticipantUids, participantIsTeam, participantIsUser} from '@shared/helpers/participant-fn';
import {TeamUiData} from '@shared/models/team';
import {UserUiData} from '@shared/models/user';

enum ParticipantType {
    User = 'USER',
    Team = 'TEAM',
}

const ParticipantTypeArray = [ParticipantType.User, ParticipantType.Team];

type Participant = UserUiData | TeamUiData;

interface CompetitionGroupParticipants {
    id: string
    competitionId: string;
    competitionStageId: string;
    competitionGroupId: string;
    participantIds: string[];
    participants: { [id: string]: Participant; };
    page: number;
    total: number;
}

interface EntityParticipants {
    id: string;
    entityId: string;
    participantIds: string[];
    participants: { [id: string]: Participant; };
    page: number;
    total: number;
}

export type {Participant, CompetitionGroupParticipants, EntityParticipants};

export {ParticipantType, ParticipantTypeArray, participantIsTeam, participantIsUser, getParticipantUids};
