import {Box, Column, ScrollView, Stack, useDisclose} from 'native-base';
import ClubhouseFeaturedChallenges, {showFeaturedChallenges} from './ClubhouseFeaturedChallenges';
import React, {useEffect} from 'react';
import {useDashboardAssets, useNewsItemsSorted} from '@ui/features/clubhouse/hooks/dashboard-hooks';
import AuthWrapper from '@ui/layout/AuthWrapper';
import ClubhouseUserActions from './ClubhouseUserActions';
import CompleteProfileCard from '@ui/features/clubhouse/components/CompleteProfileCard';
import {DashboardConfig} from '@shared/models/configs';
import DashboardLayout from '@ui/layout/DashboardLayout';
import DrillsCompletedCard from '@ui/features/clubhouse//components/DrillsCompletedCard';
import {FirstTimeLoginRedeemCoupon} from '../wallet/earn/WalletEarnRedeemCoupon';
import HandicapCard from '@ui/features/clubhouse/components/HandicapCard';
import HoverPressable from '@ui/components/buttons/HoverPressable';
import IcBell from '@ui/svg/IcBell';
import NewsItemCard from './components/engagementCards/NewsItemCard';
import {PlayBetButton} from './PlayBetButton';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import {WEB_LG_MAX_WIDTH} from '@ui/helpers/constants';
import WalletCard from '../wallet/WalletCard';
import WelcomeModal from '@ui/layout/WelcomeModal';
import {createParam} from 'solito';
import {getScreen} from '@ui/provider/navigation/screens';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface ClubhouseViewProps {
    config?: DashboardConfig;
}

const ClubhouseView = (props: ClubhouseViewProps) => {
    const {config} = props;
    const {user, isCompleted} = useUser();
    const [newsItems] = useNewsItemsSorted('clubhouse');

    return (
        <>
            <Column
                space={4}
                safeAreaBottom
                pb='8'
                px='4'
                py={4}
                flex={1}
                w='full'
            >
                {!user || isCompleted
                    ? <Box mt={-4} />
                    : <CompleteProfileCard />
                }

                <ClubhouseUserActions/>

                <WalletCard />

                <PlayBetButton />

                <Stack direction="row"
                    space={4}
                    flex={1}
                >
                    <HandicapCard />

                    <DrillsCompletedCard user={user} />
                </Stack>

                {showFeaturedChallenges(config?.featuredChallenges) &&
                <ClubhouseFeaturedChallenges featuredChallenges={config!.featuredChallenges!} />
                }

                {newsItems?.map((item, index) => (
                    <NewsItemCard key={index} item={item} mt={12} />
                ))}
            </Column>

            <FirstTimeLoginRedeemCoupon />
        </>
    );
};

const {useParam} = createParam<{showWelcome: string}>();

export const ClubhouseViewScreen = () => {
    const [assets, assetsLoading] = useDashboardAssets();
    const {isOpen: isWelcomeOpen, onOpen: onOpenWelcome, onClose: onCloseWelcome} = useDisclose();
    const [showWelcome, setShowWelcome] = useParam('showWelcome');
    const {push} = useRouter();
    const {t} = useTranslation();

    useEffect(() => {
        if(showWelcome){
            onOpenWelcome();
            setShowWelcome(undefined);
        }
    }, [showWelcome]);

    return (
        <AuthWrapper image={assets?.login?.clubhouse?.image} text={assets?.login?.clubhouse?.title?.en}>
            <DashboardLayout
                title={t('clubhouse.header.title')}
                header={{
                    selectedTab: 'clubhouse',
                    _right:
                    <HoverPressable onPress={() => push(getScreen('notifications'))}>
                        <Box px='2'>
                            <IcBell />
                        </Box>
                    </HoverPressable>,
                }}
                useMainContentContainer={false}
            >
                <PresenceLoadingScreen isLoading={assetsLoading} showContent={assets !== undefined}>
                    <Box
                        alignItems={{
                            lg: 'center',
                            base: 'flex-start',
                        }}
                        px={0}
                        pb={{
                            base: 0,
                            lg: 4,
                        }}
                        flex={1}
                    >
                        <ScrollView
                            w={{lg: WEB_LG_MAX_WIDTH, base: '100%'}}
                            flex={1}
                            showsVerticalScrollIndicator={false}
                        >
                            <ClubhouseView config={assets} />

                        </ScrollView>
                    </Box>
                </PresenceLoadingScreen>


                <WelcomeModal isOpen={isWelcomeOpen} onClose={onCloseWelcome}/>
            </DashboardLayout>
        </AuthWrapper>
    );
};
