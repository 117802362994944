import {CompetitionGroupFixture, Fixture, FixtureStatus, ResultStatus} from '@shared/models/fixture';
import {Participant, ParticipantType, participantIsTeam, participantIsUser} from '@shared/models/participant';
import {identity, last, pick, range, sortBy, toSafeInteger} from 'lodash';
import {TeamUiData} from '@shared/models/team';
import {UNLIMITED_PLAYERS_VALUE} from './fixtureCreateConstants';
import {isChallengeCourse} from './competition-fn';
import {isNil} from './lang';

const fixtureParticipantIds = (fixture: Fixture): string[] => {
    return Object.keys(fixture.participants);
};

const isCompetitionFixture = (fixture: Fixture): boolean => {
    return fixture.competition !== undefined || fixture.competitionGroup !== undefined;
};

const toCompetitionGroupFixture = (fixture: Fixture): CompetitionGroupFixture => pick(fixture, ['id', 'participantIds', 'participants', 'result', 'rule', 'round', 'knockoutMatchNumber', 'knockoutFixtureType', 'participantsOrder']);

const matchPlayScoreText = (score: number, isDormie: boolean) => {
    if (!isDormie) {
        if(score === 0) {
            return '';
        } else {
            return `${score}UP`;
        }
    }

    switch(score) {
        case 0: return '';
        case 1:
        case 2:
            return `${score}UP`;
        default: {
            const half = Math.trunc(score / 2);

            return `${half + 1}&${score % (half + 1)}`;
        }
    }
};

const winLoseScoreText = (score: number, t: (key: string) => string) => {
    if(score === 0) {
        return '';
    } else {
        return t('fixture.score.win');
    }
};

const isFixtureVerifiable = (fixture: Fixture, userAttemptingVerificationId: string): boolean => {
    return fixture.result?.status == ResultStatus.Pending &&
        fixture.participantIds.includes(userAttemptingVerificationId);
};

const isLastScoreUploader = (fixture: Fixture, uid: string): boolean => {
    const uploader = last(fixture.uploadScores)?.uploadedBy;
    if(!uploader) {
        return false;
    }

    if(fixture.participantType === ParticipantType.User) {
        return uploader === uid;
    } else {
        // Check if multiple teams
        if(Object.values(fixture.participantsOrder).length > 1){
            return findUserTeam(Object.values(fixture.participants), uid)?.participants[uploader] !== undefined;
        } else {
            return uploader === uid;
        }
    }
};

const findUserTeam = (participants: Participant[], uid: string): TeamUiData | undefined => {
    return participants.find((participant) => participantIsTeam(participant) && participant.participants[uid]) as TeamUiData;
};

const getAllFixtureTeams = (fixture: Pick<Fixture, 'participants'>) => {
    return Object.values(fixture.participants).filter(participantIsTeam);
};

const getAllFixtureUsers = (fixture: Pick<Fixture, 'participants'>) => {
    return Object.values(fixture.participants).filter((participant) => participantIsUser(participant));
};

const getAllParticipantsByType = (fixture: Pick<Fixture, 'participants'>, type: ParticipantType) => {
    return type === ParticipantType.User ? getAllFixtureUsers(fixture) : getAllFixtureTeams(fixture);
};

const fixtureTeamParticipants = (allParticipants: Participant[], team: TeamUiData) => {
    return allParticipants.filter((participant) => participantIsUser(participant) && team.participants[participant.id]);
};

const getLeftParticipants = (fixture: Fixture) => {
    const {participants, participantType, participantsOrder} = fixture;
    const leftParticipant = participants[participantsOrder[0]];
    if(!leftParticipant) {
        return [];
    }

    if(participantType === ParticipantType.User) {
        return [leftParticipant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return leftParticipant ? sortBy(participantsArray.filter((p) => (leftParticipant as TeamUiData).participants[p.id]), 'id') : [];
};

const getRightParticipants = (fixture: Fixture) => {
    const {participants, participantType, participantsOrder} = fixture;
    const rightParticipant = participants[participantsOrder[1]];
    if(!rightParticipant) {
        return [];
    }
    if(participantType === ParticipantType.User) {
        return [rightParticipant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return rightParticipant ? sortBy(participantsArray.filter((p) => (rightParticipant as TeamUiData).participants[p.id]), 'id') : [];
};

const getParticipantsByOrder = (fixture: Fixture, order: number) => {
    const {participants, participantType, participantsOrder} = fixture;
    const participant = participants[participantsOrder[order]];
    if(!participant) {
        return [];
    }

    if(participantType === ParticipantType.User) {
        return [participant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return participant ? sortBy(participantsArray.filter((p) => (participant as TeamUiData).participants[p.id]), 'id') : [];
};

const getParticipantsOrdered = (fixture: Fixture) => {
    return Object.keys(fixture.participantsOrder).map(toSafeInteger).map((index) => fixture.participants[fixture.participantsOrder[index]]);
};

const getAllParticipantsWithSubParticipants = (fixture: Fixture): [Participant, Participant[]][] => {
    const participants = Math.max(...Object.keys(fixture.participantsOrder).map(toSafeInteger));
    return range(0, participants + 1).map((index) => [fixture.participants[fixture.participantsOrder[index]], getParticipantsByOrder(fixture, index)]);
};

const numberOfParticipants = (fixture: Fixture) => {
    const {rule} = fixture;
    const {numberOfParticipants = 1} = rule;

    const participants = getAllParticipantsByType(fixture, fixture.participantType);
    return Math.max(numberOfParticipants, participants.length);
};

const canUserDeleteFixture = (fixture: Fixture, uid?: string) => {
    const {competition, status} = fixture;
    if(!uid) {
        return false;
    }

    if(competition && isChallengeCourse(competition)) {
        return fixture.uids.includes(uid);
    }

    return fixture.uid === uid && status !== FixtureStatus.Played;
};

const canUserLeaveFixture = (fixture: Fixture, uid?: string) => {
    const {status} = fixture;
    if(!uid || isCompetitionFixture(fixture)) {
        return false;
    }

    return status !== FixtureStatus.Played && fixture.uid !== uid && fixture.participantIds.includes(uid);
};

const maxNumberOfPlayers = (fixture: Fixture) => {
    const {rule} = fixture;
    const {numberOfParticipants = 1, playersPerTeam = 1} = rule;
    return numberOfParticipants * playersPerTeam;
};

const currentInvitationSize = (fixture: Fixture) => {
    return fixture.invitationIds?.length ?? 0;
};

const getAllPlayers = (fixture: Fixture) => {
    return getAllParticipantsByType(fixture, ParticipantType.User);
};

const isUnlimitedPlayers = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) === UNLIMITED_PLAYERS_VALUE;
};

const hasAllPlayers = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) === getAllPlayers(fixture).length;
};

const isFinished = (fixture: Fixture) => {
    return fixture.status === FixtureStatus.Played;
};

const openInvitationSpots = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) - currentInvitationSize(fixture) - getAllPlayers(fixture).length;
};

const getInvitationsArray = (fixture: Fixture): Participant[] => {
    const {invitationIds, invitations} = fixture;
    return invitationIds ? invitationIds.map(i => invitations?.[i]).filter(p => !isNil(p)) as Participant[] : [];
};

const hasOpenInvitationSpots = (fixture: Fixture) => {
    return openInvitationSpots(fixture) > 0;
};

const hasInvitations = (fixture: Fixture) => {
    return currentInvitationSize(fixture) > 0;
};

const getOpponent = (fixture: Fixture, uid: string) => {
    const participants = getAllParticipantsByType(fixture, fixture.participantType);
    return participants.find((participant) => participant.id !== uid);
};

const isFixturePlayable = (fixture: Fixture) => {
    return fixture.status === FixtureStatus.Playable || fixture.status === FixtureStatus.Upcoming;
};

export {
    getLeftParticipants,
    getRightParticipants,
    getParticipantsByOrder,
    getAllParticipantsWithSubParticipants,
    hasAllPlayers,
    fixtureParticipantIds,
    isCompetitionFixture,
    isLastScoreUploader,
    matchPlayScoreText,
    winLoseScoreText,
    numberOfParticipants,
    isFixtureVerifiable,
    findUserTeam,
    toCompetitionGroupFixture,
    getAllFixtureTeams,
    getAllFixtureUsers,
    getAllParticipantsByType,
    getOpponent,
    fixtureTeamParticipants,
    canUserDeleteFixture,
    isFinished,
    hasInvitations,
    currentInvitationSize,
    maxNumberOfPlayers,
    hasOpenInvitationSpots,
    openInvitationSpots,
    getInvitationsArray,
    canUserLeaveFixture,
    getParticipantsOrdered,
    isUnlimitedPlayers,
    isFixturePlayable,
};
